.menuTitles li a {
    text-transform: uppercase;
    padding: 16px;
    font-size: 18px;
    color: #8a8f98;
    font-weight: 700;
    letter-spacing: -.05em;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

.menuTitles li a.active {
    color: white;
}

@media (min-width: 1280px) {

    .menuTitles li a {
        font-size: 14px;
    }

    .active {
        color: #ffffff!important;
    }
    .active::after {
        height: 0;
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        content: "";
        border-bottom: 3px solid #80C341;
    }
}